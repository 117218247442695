<template>
  <!-- 个人信息页背景 -->
  <div

    class="back"
    :style="{
      background:
        'url(' +
        require('@/assets/images/login_success.gif') +
        ')' +
        'no-repeat',
    }"
  >
    <!-- logo -->
    <img class="logo" src="@/assets/images/login-logo.png" alt="" />
    <!-- 标题 -->
    <div class="title">智能化多媒体管理和生产系统</div>
    <!-- 个人信息容器 -->
    <div class="userinfo">
      <!-- 标题 -->
      <div class="tip">个人信息</div>
      <!-- 个人信息表单 -->
      <el-form ref="dynamicValidateForm" :model="Form" :rules="rules">
        <el-form-item label="邮箱" style="color: rgba(255, 255, 255, 0.9);
opacity: 0.6;">
          <el-input
            :disabled="true"
            v-model="email"
          ></el-input>
        </el-form-item>
        <el-form-item label="品牌" style="color: rgba(255, 255, 255, 0.9);
opacity: 0.6;" >
          <el-input
            :disabled="true"
            v-model="company_name"
          ></el-input>
        </el-form-item>
        <el-form-item label="部门" prop="department">
          <el-select
            :class="Form.department=='' ? 'select_input' : '' "
            @change="selectDepartment"
            v-model="Form.department"
            placeholder="请选择部门"
          >
            <el-option
              v-for="item in this.departmentList"
              :key="item.id"
              :label="item.department"
              :value="item.department"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职位" prop="position">
          <el-select v-model="Form.position" placeholder="请选择职位"
          :class="Form.position=='' ? 'select_input' : '' ">
            <el-option
              v-for="item in this.positionList"
              :key="item.id"
              :label="item.department"
              :value="item.department"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input :class="Form.name=='' ? 'select_input' : '' " v-model="Form.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item size="large">
          <el-button class="test" type="primary" @click="submitForm('dynamicValidateForm')"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 表单验证对象
      rules: {
        department: [
          { required: true, message: "部门不能为空", trigger: "blur" },
        ],
        position: [{ required: true, message: "请选择职位", trigger: "blur" }],
        name: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
      },
      // 表单数据对象
      Form: {
        department: "",
        position: "",
        name: "",
      },
      // 部门数据对象
      departmentList: [],
      // 职位数据对象
      positionList: [],
    };
  },
  methods: {
    // 保存按钮
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        // 通过验证在此提交个人信息
        if (valid) {
          let formData = new FormData();
          formData.append("true_name", this.Form.name);
          formData.append("department", this.Form.department);
          formData.append("position", this.Form.position);
          this.$http({
            method: "POST",
            url: "accounts/complete_userinfo",
            data: formData,
          }).then((res) => {
            //  提交成功
            if (res.data.status == 0) {
              // 将用户名做数据持久化
              localStorage.setItem('name',this.Form.name)
              localStorage.setItem('department',this.Form.department)
              localStorage.setItem('position',this.Form.position)
              // 提交到vuex中
              this.$store.commit("setUername", "")
              this.$store.commit("setDepartment", "")
              this.$store.commit("setPosition", "")
              // 跳转主页
              this.$router.push('/allrourse');
            }
          }).catch(error => console.log(error));
        } else {
          // 没通过验证返回错误，不进行下一步
          return false;
        }
      });
    },

    // 请求部门数据
    getDepartment() {
      this.$http({
        method: "GET",
        url: "accounts/get_departments",
        params: { company_name: this.$store.state.company_name },
      }).then((res) => {
        this.departmentList = res.data.data;
      }).catch(error => console.log(error));
    },
    // 请求职位数据
    getPosition() {
      this.$http({
        method: "GET",
        url: "accounts/get_positions",
        params: { department: this.Form.department },
      }).then((res) => {
        this.positionList = res.data.data;
      }).catch(error => console.log(error));
    },
    // 选择部门
    selectDepartment() {
      this.Form.position=''
      // 请求职位数据
      this.getPosition();
    },
  },
  created() {
    // console.log(1)
    // 请求部门数据
    this.getDepartment();
  },
  computed:{
    email(){
      return this.$store.state.email
    },
    company_name(){
      return this.$store.state.company_name
    }
  }
};
</script>

<style lang="scss" scoped >
// 个人信息页背景
.back {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top:0;
    left:0;
    height:100%;
    width:100%;
    background-size: cover !important;
  //logo
  .logo {
    width: 221px;
    height: 95px;
  }
  //   标题
  .title {
    height: 31px;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.9);
    letter-spacing: 4px;
    opacity: 1;
  }
  //   个人信息容器
  .userinfo {
    margin-top: 2vh;
    width: 630px;
    height: 626px;
    background: rgba(57, 56, 70, 0.6);
    .tip{
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 19px;
      color: rgba(255, 255, 255, 0.9);
      opacity: 1;
      margin-top: 98px;
      margin-left: 135px;
      margin-bottom: 30px;
    }
    /deep/.el-form-item {
      margin-left: 95px;
    }
    /deep/.el-form-item__label {
      width: 65px;
      height: 19px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.9);
      opacity: 1;
      letter-spacing: 10px;
      padding:0px;
    }
    /deep/.el-input {
      width: 388px;
      height: 40px;
    }
    /deep/.el-input__inner {
      background: #393846;
      border-radius: 5px;
      opacity: 0.6;
      color: #fff;

    }
    /deep/.el-form-item__label:before {
      content: "";
      color: #f56c6c;
      margin-right: 0px;
    }
    /deep/.el-select-dropdown {
      background-color: blue;
    }
    /deep/.el-select__caret{
      color: #ffff;
    }
    /deep/.el-form-item {
      margin-left: 72px;
    }
    .el-button--primary {
      width: 388px;
      height: 40px;
      margin-left: 64px;
    }
    .select_input{
      /deep/.el-input__inner{
        border: 1px solid rgba(255, 255, 255, 0.5);
        font-size:12px
      }
    }
    .test{
      /deep/span{
          padding-left:40px;
        letter-spacing: 40px;
      }
    }
  }
}
</style>

<style lang="scss">
// 全局的下拉选择框样式
.el-popper[x-placement^="bottom"] {
  margin-top: 5px;
}
.el-popper[x-placement^="bottom"] .popper__arrow {
  display: none;
}
.el-popper[x-placement^="top"] .popper__arrow {
  background: #32313e;
}
.el-select-dropdown {
  background: #32313e;
  border: 1px solid #5e5f72;
  opacity: 1;
}
.el-select-dropdown__item {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #292833;
}
.el-scrollbar__wrap {
  overflow: scroll;
  height: 154px;
}

.el-form-item__error {
  top: 32%;
  left: 460px;
}
</style>
